import React from "react";
import { CheckCircleIcon } from '@heroicons/react/24/outline';

const ProductCard = ({ item, setItem }) => {

  return (
    <div className="mt-0 space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-2">
      <div className="border border-gray-700 rounded-lg shadow-lg divide-y divide-gray-700 bg-gray-900 min-w-80">
        <div className="p-6">
          <h2 className="text-2xl font-semibold">{item.display}</h2>
          <p className="mt-4 text-sm text-gray-400 min-h-16">{(item.description?.summary ?? "").replace(/(<([^>]+)>)/gi, "")}</p>
          {! item.quotable ?
          <>
            <p className="mt-8">
              <span className="text-4xl font-extrabold">{item.price}</span>
              <span className="text-base font-medium text-gray-400">/mo</span>
            </p>
            <button className="mt-8 block w-full bg-indigo-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-indigo-700 transition-colors duration-200" onClick={()=>setItem(item)}>
              {item.priceValue === 0 ? "Get started for free" : `Upgrade to ${item.display}`}
            </button>
          </> :
          <>
            <p className="mt-8">
              <span className="text-4xl font-extrabold">Talk to us</span>
              <span className="text-base font-medium text-gray-400">&nbsp;</span>
            </p>
            <a href="mailto:sales@signality.ai" className="mt-8 block w-full bg-indigo-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-indigo-700 transition-colors duration-200">
              Contact Sales
            </a>
          </>
          }
        </div>
        <div className="pt-6 pb-8 px-6">
          <h3 className="text-xs font-medium tracking-wide uppercase text-gray-400">What's included</h3>
          <ul className="mt-6 space-y-4">
            {Object.entries(item.attributes ?? {}).map((attr) => (
              <li key={attr[0]} className="flex space-x-3">
                <CheckCircleIcon className="flex-shrink-0 h-5 w-5 text-green-400" aria-hidden="true" />
                <span className="text-sm text-gray-300">{attr[1]} {attr[0]}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;