import React, { useEffect, useState } from 'react';
import { useFastSpring } from '../../store/FastSpringContext';
import ProductCard from './ProductCard';
import { getUserInfo } from "../../utils/backend";
import Spinner from "../../components/Spinner";

export default function Pricing({
  user,
  organization,
  setOrganization,
  setTransactions,
  session,
}) {

  const { products, subscriptions, orderId, setOrderId } = useFastSpring();
  const [ item, setItem ] = useState();

  useEffect(() => {
    if (! item) return;
    if (
      typeof window === "undefined" ||
      ! window.fastspring ||
      ! window.fastspring.builder
    ) return;

    window.fastspring.builder.reset();

    // check for subscriptions
    if (item.subscription) {    
      // Create a payload with the new product
      const payload = {
        reset: true,
        paymentContact: {
          "email": user.email,
          "firstName": user.firstName,
          "lastName": user.lastName,
        },
        products: [{
          path: item.path,
        }],
        checkout: false,
        language: user.country?.toLowerCase(),
        tags: {
          user_id: user.id,
          organization_id: organization.id,
        }
      };

      window.fastspring.builder.push(payload);
    } else {
      window.fastspring.builder.add(item.path);
      window.fastspring.builder.tag({
        user_id: user.id,
        organization_id: organization.id,
      });
      window.fastspring.builder.recognize(
        {
          "email": user.email,
          "firstName": user.firstName,
          "lastName": user.lastName,
        }
      );
      window.fastspring.builder.viewCart();
    }
  }, [item, user, organization.id]);

  useEffect(() => {
    if (! orderId) return;
    const urlparams = (item.subscription ? {} :  { include: ['transactions'] });
    (async () => {
      for (let i = 0; i < 10; i++) { 
        await new Promise(r => setTimeout(r, 2000));
        try {
          const userdata = await getUserInfo(session.tokenized, urlparams);
          if (item.subscription) {
            if (userdata.organization.subscription && userdata.organization.subscription.initialOrderId === orderId) {
              setOrganization(userdata.organization ?? {});
              setOrderId(null);
              return;
            }
          } else {
            const transaction = (userdata.transactions ?? []).filter((t) => t.orderId === orderId);
            if (transaction.length > 0) {
              setTransactions(userdata.transactions);
              setOrderId(null);
              return;
            }
          }
        } catch (err) {
            console.error(err);
        }
      }
      alert(`Timer for orderId ${orderId} has expired, please refresh.`);
      setOrderId(null);
    })();
  }, [orderId, item, setOrderId, session, setOrganization, setTransactions]);

  if (orderId) return (
    <div className="min-h-screen bg-black text-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold sm:text-4xl">
            Waiting for order to complete
          </h2>
          <Spinner />
        </div>
      </div>
    </div>
  );
  if (organization.subscription) return (
    <div className="bg-black text-white p-6 rounded-lg max-w-3xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">Workspace plan details</h2>
      
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <div>
            <h3 className="text-gray-400">Subscription</h3>
            <p className="text-lg">{organization.subscription.display ?? ""}</p>
          </div>
          <a className="bg-purple-600 text-white px-4 py-2 rounded-md hover:bg-purple-700 transition-colors"
          target="_blank"
          rel="noopener noreferrer"
          href={organization.subscription.account?.url}
          >
            Manage
          </a>
        </div>
        
        <div className="flex justify-between items-start">
          <div>
            <h3 className="text-gray-400">Credits</h3>
            <p className="text-lg">{(organization?.creditsSubscriptionBalance ?? 0) + (organization?.creditsPurchaseBalance ?? 0)} workspace credits</p>
            <p className="text-sm text-gray-400">Credits are shared by Admins and Editors in this workspace.</p>
            <p className="ml-4 text-base mt-2">{organization?.creditsPurchaseBalance ?? 0} purchased credits</p>
            <p className="ml-4 text-sm text-gray-400">One-off payment for additional credits.</p>
            <p className="ml-4 text-base mt-2">{organization?.creditsSubscriptionBalance ?? 0} {} plan credits</p>
            <p className="ml-4 text-sm text-gray-400">Monthly credit refresh.</p>
          </div>
          {products && products.length > 0 &&
            <button className="bg-purple-600 text-white px-4 py-2 rounded-md hover:bg-purple-700 transition-colors"
              onClick={()=> setItem(products[0])}
              >
              Get credits
            </button>
          }
        </div>
        
        
      </div>
    </div>
  );
  return (
    <div className="min-h-screen bg-black text-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold sm:text-4xl">
            Simple, transparent pricing
          </h2>
          <p className="mt-4 text-xl text-gray-400">
            Choose the plan that's right for you
          </p>
        </div>
        <div className="mt-12 space-y-0 grid grid-flow-col auto-cols-fr">
          {subscriptions ? (
            subscriptions.map(
              (
                product
              ) => (
                <ProductCard
                  key={product.path}
                  item={product}
                  setItem={setItem}
                />
              )
            )
          ) : (
            <p>No products available</p>
          )}
        </div>
      </div>
    </div>
  );
}